import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./App.css";
import Servicesimport, {
  setImgElBckPstn,
  setElPositionStyles,
  getStyleForMode,
  applyGlobalStyles,
  getPageIdentifier,
  getCurrentStylesObject,
  setImageSourceStyles,
  resetGlobalStyles,
  setOriginalStyles,
  parseVersion,
  getQueryParams,
  insertDuplicatedSection,
  getGlobalDesignObject,
  handleUpdateGlobalDesignObject,
  getDeviceMode,
  getStyleForModeRevisions,
  innerPage,
  iframeElement,
} from "./Services";
import axios, { all } from "axios";
import PortalContainer from "./components/PortalContainer";
import ViewportResizer from "./components/ViewportResizer";
import SidebarNav from "./components/SidebarNav";
import StaticPage from "./StaticPage";
import _ from "lodash";
import { fetchWPRequest, wpHost } from "./api/wordpress";
import parse from "node-html-parser";
import { ScopedCssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import EditMenuPopup from "./headerMenu/EditMenuPopup";
import {
  colorToHex,
  getFile,
  getFileContent,
  getHoverColor,
  syncTimeout,
} from "./api/helpers";
import HeaderMenu from "./headerMenu/HeaderMenu";
import { MenuProvider } from "./headerMenu/HeaderMenuContext";
import {
  PopupElementPosition,
  EditTextTab,
  ImageUploader,
  PagesTab,
  PopupBackgroundImagePosition,
  StyleEditorPopup,
  PopupVersionManager,
  VersionsTab,
} from "./components/Tabs";
import SectionsToggle from "./components/Tabs/SectionsToggle";
import RemoveElements from "./components/Tabs/RemoveElements";
import * as signalR from "@microsoft/signalr";
import Publish from "./components/Tabs/Publish";

// export let globalDesignObject = null;

let alreadyRanOut = false;

const App = () => {
  const [showPencils, setShowPencils] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const [initialFontSize, setInitialFontSize] = useState("");
  const [initialColor, setInitialColor] = useState("#000000");
  const [initialText, setInitialText] = useState("");
  const [initialFontWeight, setInitialFontWeight] = useState("normal");
  const [initialBckgrndSize, setInitialBckgrndSize] = useState("cover");
  const [initialXPosition, setInitialXPosition] = useState(50);
  const [initialYPosition, setInitialYPosition] = useState(50);
  const [triggerPopulateDesignObject, setTriggerPopulateDesignObject] =
    useState(false);
  const [triggerFetchDesignObject, setTriggerFetchDesignObject] =
    useState(false);
  const [currentResolutionMode, setCurrentResolutionMode] = useState(
    getDeviceMode(),
  );
  const [popupElementBckgrndPstnOpen, setPopupElementBckgrndPstnOpen] =
    useState(false);
  const [currentGlobalGroups, setCurrentGlobalGroups] = useState([]);
  const selectedPageLS = localStorage.getItem("selectedPage");
  const [selectedPage, setSelectedPage] = useState(selectedPageLS);
  const [version, setVersion] = useState(null);
  const [dfaultStyles, setDefaultStyles] = useState(null);
  const [userEmail, setUserEmail] = useState(() => {
    const storedEmail = localStorage.getItem("userEmail");
    return storedEmail ? JSON.parse(storedEmail) : null;
  });
  const [saveDesignObjectState, setSaveDesignObjectState] = useState("empty");
  const [versions, setVersions] = useState(null);
  const [designObject, setDesignObject] = useState(null);

  const [openedSidebar, setOpenedSidebar] = useState("versionPopup");
  const siteToken = localStorage.getItem("site-token") || null;
  const [token, setToken] = useState(siteToken);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [allPages, setAllPages] = useState([]);
  const [pageCreated, setPageCreated] = useState(false);
  const [newPageSlug, setNewPageSlug] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNewUser, setIsNewUser] = useState(null);
  const [isStaticPage, setIsStaticPage] = useState(false);
  const [sectionsToToggle, setSectionsToggle] = useState([]);
  const toggleSectionsRef = useRef([]);
  const [htmlContentLoading, setHtmlContentLoading] = useState(false);
  const [revisionsList, setRevisionsList] = useState(null);
  //* const [hiddenElements, setHiddenElements] = useState(
  //*   localStorage.getItem("hiddenElements")
  //*     ? JSON.parse(localStorage.getItem("hiddenElements"))
  //*     : [],
  //* );
  const [removedElements, setRemovedElements] = useState([]);
  const [removeItemsSelectedSection, setRemoveItemsSelectedSection] =
    useState(null);

  const [connectionId, setConnectionId] = useState("");

  const handleDataInterval = useRef(null);
  const lastSavedTextValuesRef = useRef({
    text: "",
    fontSize: "",
    fontWeight: "",
    color: "",
  });

  const [error, setError] = useState(null);

  const getGlobalDesignObj = getGlobalDesignObject();
  console.log("getGlobalDesignObjgetGlobalDesignObject", getGlobalDesignObj);

  useEffect(() => {
    // Token here is hard coded but you can use a real token of a logged in user
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImJyYW5kY3JlYXRlQGdtYWlsLmNvbSIsIm5iZiI6MTcxOTAzNDk0OSwiZXhwIjoxNzE5Mzk0OTQ5LCJpYXQiOjE3MTkwMzQ5NDksImlzcyI6IippdW9oc2R1Zmg4SFVTQUpucyIsImF1ZCI6IklJSkVLTEpuZmFqamptZGoifQ.u-Zt58Q5vMiy_SPJ2Zb3j_KX-fIkuK4yrtzvNRmW5Ow';
    // const endpointBase = 'https://localhost:7170';

    if (token) {
      const endpointBase = "https://brainteaser.codersuccess.com";

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${endpointBase}/hub/signalr?access_token=${token}`)
        .build();

      connection.on("ReceiveMessage", (message) => {
        console.log(
          "FetchDesigObject our socket updated design object",
          message,
        );
        // setDesignObject(message.JsonDesign);
        const recivedJSON = JSON.parse(message);
        console.log("FetchDesigObject recivedJSON", recivedJSON);
        var readyDesignObject = JSON.parse(recivedJSON.JsonDesign);
        console.log("FetchDesigObject readyDesignObject", readyDesignObject);
        setDesignObject(readyDesignObject);
        setRevisionsList(recivedJSON.revisionsList);

        // handleUpdateGlobalDesignObject(stringJson);
        //setRevisionsList(response.data.revisionsList)
        //* localStorage.setItem("elementStyles", recivedJSON.JsonDesign);
        handleUpdateGlobalDesignObject(readyDesignObject, "socket");
        handleChangeCurrentVersion(localStorage.getItem("version"));
      });

      connection.on("ConnectionIdReciever", (connectionIdResponse) => {
        console.log("our connection id", connectionIdResponse);
        setConnectionId(connectionIdResponse);
      });

      connection.on("error", (err) => {
        console.error("SignalR connection error:", err);
        setError(err);
      });

      const startConnection = async () => {
        try {
          await connection.start();
        } catch (err) {
          console.error("Error starting SignalR connection:", err);
          setError(err);
        }
      };

      startConnection();

      return () => {
        connection.stop();
      };
    }
  }, [token]);

  const hideElements = (elements) => {
    elements.forEach((elementId) => {
      const element = innerPage.querySelector(`[data-remove="${elementId}"]`);
      if (element) {
        element.classList.add("hidden");
        console.log("hideElements", elementId, element);
      }
    });
  };

  // * useEffect(() => {
  // *   const storedHiddenElements = localStorage.getItem("hiddenElements");
  // *   if (storedHiddenElements) {
  // *     const parsedHiddenElements = JSON.parse(storedHiddenElements);
  // *     setHiddenElements(parsedHiddenElements);
  // *     hideElements(parsedHiddenElements);
  // *   }
  // * }, []);

  // * useEffect(() => {
  // *   localStorage.setItem("hiddenElements", JSON.stringify(hiddenElements));
  // * }, [hiddenElements]);

  console.log("isNewUser", isNewUser);

  function handleUserEmail(email) {
    if (userEmail === email) return;
    console.log("handleUserEmail:", email);
    setUserEmail(email);
    // const emailName = getEmailName(email);
    // setSelectedPage(emailName);
    // localStorage.setItem("selectedPage", emailName);
  }

  const handlePageChange = async (event) => {
    const slug = event.target.value.toLowerCase();
    const versions = await fetchPageVersions(slug);
    const lastVersion = versions[versions.length - 1];
    localStorage.setItem("selectedPage", slug);
    handleChangeCurrentVersion(lastVersion);
    await fetchDesignObject(slug, lastVersion, "handlePageChange");
    setSelectedPage(slug);
  };

  const fetchPageVersions = async (slug) => {
    // selectedVersion = selectedVersion == null ? localStorage.getItem("version") : selectedVersion;
    try {
      const response = await axios.get(
        `https://brainteaser.codersuccess.com/api/popup/getPageVersions?PageSlug=${slug}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (!response.data) {
        console.log("fetchPageVersions No data received");
        setVersions(null);
        return [];
      } else {
        setVersions(response.data.pageVersions);
        console.log("fetchPageVersions", response.data);
        return response.data.pageVersions;
      }
    } catch (error) {
      console.error("fetchPageVersions: ", error);
      return [];
    }
  };

  const fetchDesignObject = async (
    slug,
    selectedVersion = null,
    runFrom = null,
  ) => {
    console.log(
      "Fetching design object for slug:",
      slug,
      "Selected Version:",
      selectedVersion,
      "fetchDesignObject Run from: ",
      runFrom,
    );
    // selectedVersion = selectedVersion == null ? localStorage.getItem("version") : selectedVersion;
    try {
      const response = await axios.post(
        "https://brainteaser.codersuccess.com/api/popup/getDesignRevisionJson",
        {
          PageSlug: slug,
          resolutionId: getDeviceMode(),
          versionName: selectedVersion,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // console.log("FetchDesigObject", JSON.parse(response.data.designJson));
      if (
        !response.data ||
        response.data == "The user don't have design json with this slug."
      ) {
        console.log("FetchDesigObject No data received");
        //* localStorage.removeItem("elementStyles");
        handleUpdateGlobalDesignObject(null, "get design api null result");
        localStorage.removeItem("version");
        resetVErsionStyles();
        // setVersions(null);
      } else {
        // const currentStyles = JSON.parse(response.data);
        //* localStorage.setItem("elementStyles", JSON.stringify(response.data));

        var readyDesignObject = JSON.parse(response.data.designJson);
        handleUpdateGlobalDesignObject(
          readyDesignObject,
          "get design api with result",
        );
        setRevisionsList(response.data.revisionsList);
        //const { versions } = parseVersion();
        // setVersions(response.data.pageVersions);
        //setDesignObject(readyDesignObject);
        // const firstVersion = response.data.pageVersions[0]
        console.log(
          "Fetching design object for slug current response.data.pageVersions",
          response.data.pageVersions,
        );

        handleChangeCurrentVersion(selectedVersion);
      }
    } catch (error) {
      console.error(
        "Fetching design object for slug current response.data.pageVersions error",
        error,
      );
      //resetVErsionStyles();
      //setVersions(null);
    } finally {
      console.log("onPageSlugChange", slug);
      setSelectedPage(slug);
    }
  };

  function setLatestVersion(obj) {
    const versions = Object.keys(obj);
    if (versions.length === 0) {
      return null; // Return null if the object is empty
    }

    const latestVersion = versions.reduce((latest, current) => {
      return current > latest ? current : latest;
    });

    return latestVersion;
  }

  const handleCreatePage = async (
    pageSlug = null,
    versionName = "version1",
  ) => {
    const slug = pageSlug ? pageSlug : newPageSlug;
    let saveStyles = {};
    setNewPageSlug(slug);
    setSelectedPage(slug);
    saveStyles = {};
    // saveStyles[getPageIdentifier()] = [versionName];
    //* localStorage.setItem("elementStyles", JSON.stringify(saveStyles));
    handleUpdateGlobalDesignObject(
      saveStyles,
      "new page with its object added",
    ); // clear
    localStorage.setItem("selectedPage", slug);
    localStorage.setItem("version", versionName);
    try {
      const response = await axios.post(
        "https://brainteaser.codersuccess.com/api/popup/saveDesignRevisionJson",
        {
          jsonDesign: JSON.stringify(saveStyles),
          PageSlug: slug,
          ConnectionId: connectionId,
          versionChangedId: versionName,
          resolutionId: getDeviceMode(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("handleCreatePage", response.data);
      if (response.data && response.data !== "-1") {
        setNewPageSlug("");
        setPageCreated(true);
        //setVersions(null);
        getAllPages(userEmail, token);
        await fetchDesignObject(slug, versionName, "handleCreatePage");
        await fetchPageVersions(selectedPage);
      } else {
        setPageCreated(false);
        console.error("Failed to create page");
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const checkIfNewUser = (pages) => {
    if (pages.length === 0) {
      setIsNewUser(true);
    } else {
      setIsNewUser(false);
    }
  };

  const getAllPages = async (email, token) => {
    if (!email || !token) {
      console.error("Email and token are required to fetchAllPages");
      return;
    }

    try {
      const response = await axios.get(
        "https://brainteaser.codersuccess.com/api/popup/getPagesList",
        {
          params: {
            email,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const pages = response.data;
      console.log("Pages:", pages);
      setAllPages(pages);
      return pages;
    } catch (error) {
      console.error("Error fetching pages:", error);
      return [];
    }
  };

  useEffect(() => {
    // sets original styles if not set already to be able to reset styles when user receives an empty response from get_design_object
    const isOriginalStylesSet = localStorage.getItem("originalStyles");
    if (!isOriginalStylesSet) {
      setOriginalStyles();
    }
  }, []);

  useEffect(() => {
    if (!alreadyRanOut) {
      const savedVersion = localStorage.getItem("version");
      const selectedPage = localStorage.getItem("selectedPage");
      console.log(
        "Fetching design object for slug onload",
        savedVersion + " " + selectedPage,
      );
      if (savedVersion && selectedPage) {
        handleChangeCurrentVersion(savedVersion);
      }
      function handleResize() {
        setCurrentResolutionMode(getDeviceMode());
      }

      // window.addEventListener("resize", handleResize);
      alreadyRanOut = true;
      // return () => {
      //   window.removeEventListener("resize", handleResize);
      // };
    }
  }, []);

  const handleResolutionMode = async () => {
    const resolution = getDeviceMode();
    setCurrentResolutionMode(resolution);
  };

  const resetStateAfterDelay = () => {
    setTimeout(() => {
      setSaveDesignObjectState("empty");
    }, 3000);
  };

  // added debounce technique for cases when user changes styles to avoid multiple requests

  const saveDesignObjectFunc = async (options = {}) => {
    const {
      isManual = false,
      passedVersion = null,
      isEmptyDesign = false,
    } = options;
    const slug = localStorage.getItem("selectedPage");
    const pageVersion = passedVersion ? passedVersion : version;
    const objectToSave = getGlobalDesignObject();
    const jsonDesign = isEmptyDesign ? {} : objectToSave;
    console.log("objectToSave", objectToSave);
    console.log("saveDesignObjectFunc_getDeviceMode", getDeviceMode());
    if (!token || !slug) {
      console.warn("Please login and select current page to save your changes");
      return;
    }
    if (isManual) {
      setSaveDesignObjectState("pending");
    }
    resetStateAfterDelay();

    try {
      const response = await axios.post(
        "https://brainteaser.codersuccess.com/api/popup/saveDesignRevisionJson",
        {
          jsonDesign: JSON.stringify(jsonDesign),
          PageSlug: slug,
          ConnectionId: connectionId,
          versionChangedId: pageVersion,
          resolutionId: getDeviceMode(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      console.log("response", response);
      if (isManual) {
        setSaveDesignObjectState("success");
      }
      resetStateAfterDelay();
    } catch (error) {
      console.error("Error saving design object:", error);
      setSaveDesignObjectState("error");
      resetStateAfterDelay();
      return false;
    }
  };
  const saveDesignObject = _.debounce(saveDesignObjectFunc, 500);

  // saving new version and fetching all versions to update versions state
  const saveNewVersion = async (passedVersion, isEmptyDesign) => {
    // isEmptyDesign is true when user creates a new version without any changes
    await saveDesignObjectFunc({ passedVersion, isEmptyDesign });
    await fetchPageVersions(selectedPage);
    fetchDesignObject(selectedPage, passedVersion, "saveNewVersion");
    handleChangeCurrentVersion(passedVersion);
  };
  useEffect(() => {
    const token = localStorage.getItem("googleAccessToken");
    if (token) {
      handleLogin(token);
    }

    return () => {};
  }, [userEmail]);

  const handleLogin = async (token) => {
    try {
      const params = {
        Token: JSON.parse(token),
      };
      const response = await axios.post(
        `https://brainteaser.codersuccess.com/api/auth/google-login`,
        params,
      );
      const { data } = response;
      console.log("handleLogin", data);
      if (data === "Invalid token") {
        console.log("logout");
        setToken(null);
        setIsTokenValid(false);
        setIsLoggedIn(false);
        return;
      }
      localStorage.setItem("site-token", data?.token);
      setToken(data?.token);
      setIsLoggedIn(true);
    } catch (error) {
      console.log("error", error);
      setToken(null);
      setIsTokenValid(false);
      setIsLoggedIn(false);
    }
  };

  const fetchDesignObjectAnonymous = async (slug, email, version) => {
    console.log("Fetching design object for slug:", slug);
    try {
      const currRes = getDeviceMode();
      const response = await axios.post(
        `https://brainteaser.codersuccess.com/api/popup/getDesignRevisionJsonAnonymous2`,
        { pageSlug: slug, email, versionName: version, resolutionId: currRes },
      );
      console.log("getDesignRevisionJsonAnonymous2", response.data);
      if (
        !response.data ||
        response.data === "The user don't have design json with this slug."
      ) {
        console.log("No data received");
        //* localStorage.removeItem("elementStyles");
        handleUpdateGlobalDesignObject(
          null,
          "fetchDesignObjectAnonymous if no data",
        ); // clear
        localStorage.removeItem("version");
      } else {
        // const currentStyles = JSON.parse(response.data);

        //* localStorage.setItem("elementStyles", JSON.stringify(response.data));
        handleUpdateGlobalDesignObject(
          response.data.designJson,
          "fetchDesignObjectAnonymous if there is data",
        ); // anonymous not sure that it's an object
        handleChangeCurrentVersion(version);
      }
    } catch (error) {
      console.error("Error fetching design object:", error);
    } finally {
      console.log("onPageSlugChange", slug);
    }
  };

  useEffect(() => {
    const initializeApp = async () => {
      // console.log('initializeApp', token, userEmail)
      const {
        page: pageNameFromUrl,
        email: emailFromUrl,
        version: versionFromUrl,
      } = getQueryParams(window.location.search);
      const selectedPageFromStorage = localStorage.getItem("selectedPage");
      const versionNameFromStorage = localStorage.getItem("version");

      console.log("URL params:", pageNameFromUrl, emailFromUrl, versionFromUrl);
      console.log(
        "Local storage:",
        selectedPageFromStorage,
        versionNameFromStorage,
      );

      // Case 1: URL has page and email (anonymous user)
      if (pageNameFromUrl && emailFromUrl) {
        console.log("Case 1: Anonymous user with URL params");
        await fetchDesignObjectAnonymous(
          pageNameFromUrl,
          emailFromUrl,
          versionFromUrl,
        );
        setSelectedPage(pageNameFromUrl);
        setUserEmail(emailFromUrl);
        setIsStaticPage(true);
        return;
      }

      // For logged-in users, first fetch all pages
      if (userEmail && token) {
        const pages = await getAllPages(userEmail, token);
        console.log("pages", pages);
        const newUserStatus = checkIfNewUser(pages);
        setIsNewUser(newUserStatus);

        // Case 2: New user with email
        if (newUserStatus) {
          console.log("Case 2: New user");
          await createNewPageNVerion();
          return;
        }

        // Case 3: Logged in user with saved page and version in local storage
        if (selectedPageFromStorage && versionNameFromStorage) {
          console.log("Case 3: Logged in user with saved page and version");
          setSelectedPage(selectedPageFromStorage);
          await fetchDesignObject(
            selectedPageFromStorage,
            versionNameFromStorage,
            "fetchDesignObject useEffect Case 3",
          );
          fetchPageVersions(selectedPageFromStorage);
          return;
        }

        // Case 4: Logged in user without saved page or version
        if (Array.isArray(pages) && pages.length > 0) {
          const lastCreatedPage = pages[pages.length - 1]?.pageSlug;
          if (lastCreatedPage) {
            setSelectedPage(lastCreatedPage);
            localStorage.setItem("selectedPage", lastCreatedPage);

            const versions = await fetchPageVersions(lastCreatedPage);
            const latestVersion =
              versions && versions.length > 0
                ? versions[versions.length - 1]
                : null;

            if (latestVersion) {
              localStorage.setItem("version", latestVersion);
              await fetchDesignObject(
                lastCreatedPage,
                latestVersion,
                "fetchDesignObject useEffect",
              );
            } else {
              console.error("No version available for the last created page");
            }
          } else {
            console.error("Last created page is undefined");
          }
        } else {
          console.error("No pages available");
        }
      } else {
        console.log("User not logged in");
      }
    };

    initializeApp();
  }, [userEmail, token]);

  const createNewPageNVerion = async () => {
    const slug = userEmail.split("@")[0];
    handleCreatePage(slug, version);
  };

  const appRef = useRef(null);
  const setInitialElementColor = (el) => {
    const computedStyle = window.getComputedStyle(el);

    // Access the color property from the computed styles
    var color = computedStyle.color;
    ////console.log("computed color", color);
    setInitialColor(rgbToHex(color));
  };

  const saveImgBckPstn = (imgPstn, elementId, resolutionMode) => {
    const savedStyles = getGlobalDesignObject() || {};

    savedStyles["bckgrndPosition"] = savedStyles["bckgrndPosition"] || {};

    // Save the image position and size
    savedStyles["bckgrndPosition"][elementId] = { imgPstn };
    console.log(
      "getStyleForModeRevisions handleUpdateGlobalDesignObject saveImgBckPstn",
      savedStyles,
    );
    // Update localStorage
    //* localStorage.setItem("elementStyles", JSON.stringify(savedStyles));
    handleUpdateGlobalDesignObject(savedStyles, "saveImgBckPstn"); // clear
  };

  const handleSaveImgBckPstn = (
    imgPstn,
    el = currentElement,
    resolutionMode = currentResolutionMode,
  ) => {
    if (!el || !el.id) {
      console.error("Element does not have a unique ID");
      return;
    }

    // Set image background position and size in dom
    setImgElBckPstn(imgPstn, el);

    // Save the styles to localStorage
    saveImgBckPstn(imgPstn, el.id, resolutionMode);
    saveDesignObject();
    // Optionally, close the popup or perform other UI updates
    // setPopupImgBckgrndPstnOpen(false);
  };

  function rgbToHex(rgb) {
    // Extract the integer values for red, green, and blue
    let [r, g, b] = rgb.match(/\d+/g);

    // Convert each value to a two-digit hexadecimal value and concatenate
    return (
      "#" +
      [r, g, b]
        .map((x) => {
          const hex = parseInt(x).toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  }

  const addPencilIcons = () => {
    // Select only elements with data-edit="main"
    const elements = innerPage.querySelectorAll('[data-edit="main"]');

    ////console.log("elements", elements);

    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      if (el.querySelector(".pencil-icon")) return;
      const icon = document.createElement("div");
      icon.className = "pencil-icon";
      icon.innerHTML =
        "<button type='button' class='edit-add-action-btn'>edit</button>";

      if (el.hasAttribute("data-edit-section-parent")) {
        el.closest("section").appendChild(icon);
      } else {
        el.appendChild(icon);
      }

      icon.onclick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const elCopy = el.cloneNode(true);
        elCopy.querySelector(".pencil-icon")?.remove();

        let thisInitialText = "";
        const counterChild = Array.from(elCopy.children).find(
          (childEl) => childEl.attributes["data-digits-counter"],
        );
        if (counterChild) {
          thisInitialText = counterChild.innerText;
        } else {
          thisInitialText = elCopy.innerText;
        }

        console.log(
          "edit element clicked",
          thisInitialText,
          el.style.fontSize,
          el.style.color,
        );
        const computedStyles = window.getComputedStyle(el);
        const fontSize =
          computedStyles.getPropertyValue("font-size") ??
          Math.round(computedStyles.getPropertyValue("font-size"));
        const color = computedStyles.getPropertyValue("color");
        const fontWeight = computedStyles.getPropertyValue("font-weight");
        console.log("fontSizeforEach", fontSize, color, fontWeight);
        setCurrentElement(el);
        setInitialFontSize(fontSize);
        setInitialFontWeight(fontWeight);
        setInitialText(thisInitialText);
        setInitialElementColor(el);
        // setShowPopup(true);
        setOpenedSidebar("editText");
      };
    });
  };

  const parsePosition = (position) => {
    const value = parseInt(position, 10);
    return isNaN(value) ? 50 : value; // Default to 50 if parsing fails
  };

  const setInitialBckgrundPstn = (el) => {
    const computedStyle = window.getComputedStyle(el);
    const bgPosition = computedStyle.backgroundPosition.split(" ");
    setInitialXPosition(parsePosition(bgPosition[0]));
    setInitialYPosition(parsePosition(bgPosition[1]));
    setInitialBckgrndSize(computedStyle.backgroundSize);
  };

  const addPositionIcons = () => {
    // Select only elements with data-edit="main"
    const elements = innerPage.querySelectorAll('[data-imagePosition="main"]');

    ////console.log("elements our position els", elements);

    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      const icon = document.createElement("div");
      icon.className = "pencil-icon";
      icon.innerHTML =
        "<button class='edit-add-action-btn' type='button'>position</button>";
      el.appendChild(icon);
      icon.onclick = () => {
        setCurrentElement(el);
        setInitialBckgrundPstn(el);
        // setPopupImgBckgrndPstnOpen(true);
        setOpenedSidebar("popupImgBckgrndPstn");
      };
    });
  };

  const addElPositionIcons = () => {
    // Select only elements with data-edit="main"
    const elements = innerPage.querySelectorAll('[data-elPosition="main"]');

    ////console.log("elements our position els", elements);

    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      const icon = document.createElement("div");
      icon.className = "pencil-icon";

      icon.innerHTML =
        "<button class='edit-add-action-btn'>ElPosition</button>";
      el.appendChild(icon);
      icon.onclick = () => {
        setCurrentElement(el);
        setPopupElementBckgrndPstnOpen(false);
        setPopupElementBckgrndPstnOpen(true);
        setOpenedSidebar("popupElementBckgrndPstn");
      };
    });
  };

  const addStyleIcons = () => {
    // Select only elements with data-edit="main"
    const elements = innerPage.querySelectorAll("[data-style]");
    console.log("addStyleIcons", elements);
    ////console.log("elements our position els", elements);

    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      if (el.querySelector(".pencil-icon")) return;
      const icon = document.createElement("div");
      icon.className = "pencil-icon";
      icon.innerHTML =
        "<button class='edit-add-action-btn' style='background-color: #1E90FF !important; color: #fff !important'>Style</button>";
      const dataStyleValue = el.getAttribute("data-style");

      // Split the value by commas
      const groups = dataStyleValue.split(",");

      el.appendChild(icon);
      icon.onclick = () => {
        setCurrentElement(el);
        setCurrentGlobalGroups(groups);
        setOpenedSidebar("stylePopup");
      };
    });
  };
  const openGlobalStyles = () => {
    const dataStyleValue =
      "General,root,font family,border radius,container,main colors,text content,links";
    const groups = dataStyleValue.split(",");
    setCurrentGlobalGroups(groups);
    setOpenedSidebar("stylePopup");
  };

  const addImageIcons = () => {
    // Select only elements with data-edit="main"
    const elements = innerPage.querySelectorAll('[data-image="main"]');
    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      const icon = document.createElement("div");
      icon.className = "image-icon";
      icon.innerHTML =
        "<button type='button' class='edit-add-action-btn' style='cursoer:pointer'>upload</button>";
      const target = el.tagName.toLowerCase() === "img" ? el.parentElement : el;
      target.appendChild(icon);
      icon.onclick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentElement(el);
        setOpenedSidebar("imageUpload");
      };
    });
  };

  const removeAllIcons = () => {
    // Select and remove only the pencil icons within elements with data-edit="main"
    const icons = innerPage.querySelectorAll(".pencil-icon");
    icons.forEach((icon) => icon.remove());
    const imageIcons = innerPage.querySelectorAll(".image-icon");
    imageIcons.forEach((icon) => icon.remove());
    setShowPencils(false);
  };

  const togglePencils = () => {
    setShowPencils(!showPencils);
  };

  useEffect(() => {
    if (showPencils) {
      console.log("showPencils", showPencils);
      addPencilIcons();
    } else {
      console.log("showPencils", showPencils);
      removeAllIcons();
    }
  }, [showPencils]);

  function setElementTextStyles(element, text, fontSize, color, fotWeight) {
    if (element) {
      // Save the pencil icon element before replacing the innerHTML
      const pencilIcon = element.querySelector(".pencil-icon");

      // Update the element's text content

      const counterChild = Array.from(element.children).find(
        (childEl) => childEl.attributes["data-digits-counter"],
      );
      if (counterChild) {
        counterChild.textContent = text;
        // counterChild.setAttribute("data-digits-counter", text);
      } else element.textContent = text;

      // Apply the font size and color styles
      element.style.fontSize = fontSize;
      element.style.color = color;
      element.style.fontWeight = fotWeight;

      // Reappend the pencil icon to the element
      if (pencilIcon) {
        element.appendChild(pencilIcon);
      }
    }
  }

  const saveElementTextStyles = (
    text,
    fontSize,
    color,
    elementId,
    resolutionMode,
  ) => {
    // const savedStyles = getCurrentStylesObject(version, resolutionMode);
    // var pageIdentifier = getPageIdentifier();
    let savedStyles = getGlobalDesignObject() || {};
    console.log("saveElementTextStylessavedStyles", savedStyles);
    savedStyles["styles"] = savedStyles["styles"] || {};

    savedStyles["styles"][elementId] = { text, fontSize, color };

    //* localStorage.setItem("elementStyles", JSON.stringify(savedStyles));
    handleUpdateGlobalDesignObject(savedStyles, "saveElementTextStyles"); // clear
  };

  const handleSaveFontSize = (
    text,
    fontSize,
    color,
    fontWeight,
    el = currentElement,
    resolutionMode = currentResolutionMode,
  ) => {
    clearInterval(handleDataInterval.current);
    if (!el || !el.id) {
      console.error("Element does not have a unique ID");
      return;
    }

    setElementTextStyles(el, text, fontSize, color, fontWeight);
    saveElementTextStyles(text, fontSize, color, el.id, resolutionMode);

    const shouldSave =
      text !== lastSavedTextValuesRef.current.text ||
      fontSize !== lastSavedTextValuesRef.current.fontSize ||
      fontWeight !== lastSavedTextValuesRef.current.fontWeight ||
      color !== lastSavedTextValuesRef.current.color;

    if (shouldSave) {
      handleDataInterval.current = setTimeout(async () => {
        lastSavedTextValuesRef.current = { text, fontSize, fontWeight, color };
        saveDesignObject();
      }, 1500);
    }
  };

  const handleSaveImage = (
    imageUrl,
    el = currentElement,
    resolutionMode = currentResolutionMode,
  ) => {
    if (!el || !el.id) {
      console.error("Element does not have a unique ID");
      return;
    }

    setElementImage(el, imageUrl);
    saveElementImage(imageUrl, el.id, resolutionMode);
    // setShowPopup(false);
    setOpenedSidebar("");
    saveDesignObject(); // save design on image type select
  };

  const saveElementImage = (imageUrl, elementId, resolutionMode) => {
    // const savedStyles = getCurrentStylesObject(version, resolutionMode);
    // var pageIdentifier = getPageIdentifier();

    const savedStyles = getGlobalDesignObject() || {};

    savedStyles["image"] = savedStyles["image"] || {};

    savedStyles["image"][elementId] = {
      imageUrl,
    };

    //* localStorage.setItem("elementStyles", JSON.stringify(savedStyles));
    handleUpdateGlobalDesignObject(savedStyles, "saveElementImage"); // clear
  };

  const setElementImage = (element, imageUrl) => {
    if (element) {
      if (element.tagName === "IMG") {
        element.src = imageUrl;
      } else {
        element.style.backgroundImage = `url('${imageUrl}')`;
      }
    } else {
      if (element.tagName === "IMG") {
        element.removeAttribute("src");
      } else {
        element.removeAttribute("style");
      }
    }
  };

  const applyImageSourceStyles = (savedStyles) => {
    const elements = innerPage.querySelectorAll('[data-image="main"]');
    elements.forEach((element) => {
      setImageSourceStyles(savedStyles, element);
      //setElementimagePosition(savedStyles, )
    });
  };

  const resetImageSourceStyles = () => {
    const elements = innerPage.querySelectorAll('[data-image="main"]');

    elements.forEach((element) => {
      element.removeAttribute("style");
    });
  };

  const applyImageBackgroundPositionStyles = (savedStyles) => {
    const elements = innerPage.querySelectorAll('[data-imagePosition="main"]');

    elements.forEach((element) => {
      const styles = getStyleForModeRevisions(
        savedStyles,
        element.id,
        "bckgrndPosition",
      );
      // console.log("applyImageBackgroundPositionStylesReadyStyle for an element", styles);
      if (styles) {
        // console.log("we try to set background position styles true", styles);
        setImgElBckPstn(styles.imgPstn, element);
      } else {
        element.removeAttribute("style");
      }
    });
  };

  const resetImageBackgroundPOsition = () => {
    const elements = innerPage.querySelectorAll('[data-imagePosition="main"]');
    elements.forEach((element) => {
      // console.log("resetImageBackgroundPOsition", element);
      element.removeAttribute("style");
    });
  };

  const applyElPositionStyles = (savedStyles) => {
    console.log(
      "getStyleForModeRevisions applyElPositionStyles savedStyles",
      savedStyles,
    );
    const elements = innerPage.querySelectorAll('[data-elPosition="main"]');
    elements.forEach((element) => {
      let styles;

      styles = getStyleForModeRevisions(savedStyles, element.id, "elPosition");

      if (styles) {
        // //console.log("debugVerion our text styles", styles);
        setElPositionStyles(styles, element.id);
      } else {
        element.removeAttribute("style");
      }
    });
  };

  const resetElPositionStyles = () => {
    const elements = innerPage.querySelectorAll('[data-elPosition="main"]');

    elements.forEach((element) => {
      element.removeAttribute("style");
    });
  };

  const toggleVersionPopup = () => {
    // const newVersionPopupState = !versionPopupOpen;
    // console.log("handleKeyDown  newVerionPopup", newVersionPopupState);
    // setVersionPopupOpen(prevVersionPopup => !prevVersionPopup);
    setOpenedSidebar((prev) => {
      if (prev === "versionPopup") {
        return "";
      } else {
        return "versionPopup";
      }
    });
  };

  const applyTextAndFontStyles = (savedStyles) => {
    const elements = innerPage.querySelectorAll('[data-edit="main"]');
    elements.forEach((element) => {
      // const styles = getStyleForMode(
      //   savedStyles,
      //   resolutionMode,
      //   element.id,
      //   "styles",
      //   selectedVersion,
      // );
      const styles = savedStyles["styles"]?.[element.id];

      if (styles) {
        // //console.log("debugVerion our text styles", styles);
        element.style.fontSize = styles.fontSize;
        element.style.color = styles.color;
        if (styles.text != null) {
          const counterChild = Array.from(element.children).find(
            (childEl) => childEl.attributes["data-digits-counter"],
          );
          if (counterChild) {
            counterChild.innerHTML = styles.text;
          } else element.innerHTML = styles.text;
        }
      }
      // testRemoveStyles
      else {
        element.removeAttribute("style");
      }
    });
  };

  const resetTextAndFontStyles = () => {
    const elements = innerPage.querySelectorAll('[data-edit="main"]');
    elements.forEach((element) => {
      element.removeAttribute("style");
    });
  };

  const handleChangeCurrentVersion = (newVersion) => {
    if (!newVersion) {
      localStorage.removeItem("version");
      setVersion(null);
      return;
    }
    setVersion(newVersion.toString());
    const selectedPage = localStorage.getItem("selectedPage");
    handleApplyDesignOptionsOnChange(newVersion, selectedPage);
    //setTriggerPopulateDesignObject((prev) => !prev); // Toggle the trigger
    console.log("FetchDesigObject handleChangeCurrentVersion", newVersion);
    localStorage.setItem("version", newVersion);
  };

  // useEffect(() => {
  //   console.log(
  //     "handleChangeCurrentVersion version listner to Apply design",
  //     version,
  //   );
  //   handleApplyDesignOptionsOnChange();
  // }, [triggerPopulateDesignObject]);

  async function handleApplyDesignOptionsOnChange() {
    //* const savedStyles = JSON.parse(localStorage.getItem("elementStyles"));
    const savedStyles = getGlobalDesignObject();
    if (savedStyles == null || savedStyles == undefined) {
      return;
    }

    await resetPageHTML();
    applySectionsSettings(savedStyles);

    // console.log("savedStyles_useEffect", savedStyles);
    applyImageBackgroundPositionStyles(savedStyles);
    applyElPositionStyles(savedStyles);
    applyTextAndFontStyles(savedStyles);
    applyImageSourceStyles(savedStyles);
    applyGlobalStyles(savedStyles);
    applyRemovedItems(savedStyles);
    applySectionsOrders();
    // }, [currentResolutionMode, version, pageSlug]);
  }
  async function resetPageHTML() {
    const baseHtmlFile = await getFileContent("static-page.html");
    const htmlContent = parse(baseHtmlFile);
    innerPage.querySelector("body").innerHTML =
      htmlContent.querySelector("body");
    setTimeout(() => {
      iframeElement?.contentWindow?.refreshScripts();
    }, 0);
  }

  const resetVErsionStyles = () => {
    resetImageBackgroundPOsition();
    resetElPositionStyles();
    resetTextAndFontStyles();
    resetImageSourceStyles();
    resetGlobalStyles();
  };

  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      // console.log('handleKeyDown', event.code, event.ctrlKey, event.shiftKey, event.altKey);

      // Check if the CapsLock is on
      let isCapsLockOn = false;
      if (typeof event.getModifierState === "function") {
        isCapsLockOn = event.getModifierState("CapsLock");
      } else {
        // Fallback for browsers that don't support event.getModifierState
        isCapsLockOn =
          event.key &&
          event.key.length === 1 &&
          event.key === event.key.toUpperCase();
      }

      if (
        (event.altKey && event.ctrlKey && event.code === "KeyU") ||
        (event.ctrlKey && event.code === "KeyU")
      ) {
        // Ctrl + U to open
        event.preventDefault();
        console.log("handleKeyDown u clicked!");
        addImageIcons();
      } else if (
        (event.altKey &&
          event.ctrlKey &&
          event.code === "KeyP" &&
          !event.shiftKey &&
          !isCapsLockOn) ||
        (event.ctrlKey &&
          event.code === "KeyP" &&
          !event.shiftKey &&
          !isCapsLockOn)
      ) {
        // Ctrl + p to open
        event.preventDefault();
        console.log("handleKeyDown p clicked!");
        addElPositionIcons();
      } else if (
        (event.altKey &&
          event.ctrlKey &&
          event.code === "KeyP" &&
          (event.shiftKey || isCapsLockOn)) ||
        (event.ctrlKey &&
          event.code === "KeyP" &&
          (event.shiftKey || isCapsLockOn))
      ) {
        // Ctrl + P to open
        event.preventDefault();
        console.log("handleKeyDown P clicked!");
        addPositionIcons();
      } else if (
        (event.altKey && event.ctrlKey && event.code === "KeyS") ||
        (event.ctrlKey && event.code === "KeyS")
      ) {
        // Ctrl + S to open
        event.preventDefault();
        console.log("handleKeyDown s clicked!");
        addStyleIcons();
      } else if (
        (event.altKey && event.ctrlKey && event.code === "KeyE") ||
        (event.ctrlKey && event.code === "KeyE")
      ) {
        // Ctrl + E to open
        console.log("handleKeyDown e clicked!");
        event.preventDefault();
        togglePencils();
      } else if (
        (event.altKey && event.ctrlKey && event.code === "KeyC") ||
        (event.ctrlKey && event.code === "KeyC")
      ) {
        // Ctrl + C to open
        event.preventDefault();
        console.log("handleKeyDown c clicked");
        toggleVersionPopup();
      } else if (event.code === "Escape") {
        // Esc to close
        console.log("handleKeyDown Escape clicked");
        removeAllIcons();
        setOpenedSidebar("");
      }
    };

    // COMMENTED OUT FOR NOW
    // // Add event listener
    // if (!isStaticPage) {
    //   document.addEventListener('keydown', handleKeyDown);
    //   innerPage.addEventListener('keydown', handleKeyDown);
    // }

    // // Cleanup function to remove the event listener
    // return () => {
    //   if (!isStaticPage) {
    //     document.removeEventListener('keydown', handleKeyDown);
    //     innerPage.removeEventListener('keydown', handleKeyDown);
    //     removeAllIcons();
    //   }
    // };
  }, [isStaticPage]);

  useEffect(() => {
    // function to add class to the body when sidebar is open to be able to control the width of the content
    const body = document.querySelector("body");
    if (isSidebarOpen && !isStaticPage) {
      body.classList.add("has-sidebar");
      body.classList.remove("static-page");
    } else {
      body.classList.remove("has-sidebar");
      removeAllIcons();
    }
  }, [isSidebarOpen, isStaticPage]);

  // remove preloader when the app is loaded
  useEffect(() => {
    const preloader = document.querySelector(".preloader-container");
    if (preloader) {
      preloader.remove();
    }
  }, []);

  const closeNdSave = () => {
    saveDesignObject();
    setOpenedSidebar("");
  };

  const handleToggleStaticPage = () => {
    setIsStaticPage((prev) => !prev);
  };

  useLayoutEffect(() => {
    const textObject = {};
    const sections = Array.from(innerPage.querySelectorAll("[data-section]"));

    // Create an array of section elements with their original index
    const sectionData = sections.map((section, index) => ({
      element: section,
      index: index,
    }));

    // Sort the sectionData array based on the element's position in the HTML
    sectionData.sort(
      (a, b) =>
        a.element.compareDocumentPosition(b.element) &
        Node.DOCUMENT_POSITION_PRECEDING,
    );

    // Process the sections in the correct order
    sectionData.forEach(({ element, index }) => {
      const sectionName = element.getAttribute("data-section");
      const sectionElements = element.querySelectorAll("[data-text]");

      textObject[sectionName] = [];
      sectionElements.forEach((el) => {
        const textName = el.getAttribute("data-text");
        const text = el.textContent.trim();
        const description = textName
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        const limit = text.length || el.getAttribute("data-limit"); // Use text length by default, data-limit as an optional override

        textObject[sectionName].push({ description, text, limit });
      });
    });

    // Process the head section
    const headSection = innerPage.querySelector('[data-section="Head"]');
    if (headSection) {
      const headTitle = innerPage.querySelector("title[data-text]");
      const headDescription = innerPage.querySelector(
        'meta[name="description"][data-text]',
      );

      if (headTitle) {
        const titleText = headTitle.getAttribute("data-text");
        const titleLimit =
          headTitle.textContent.trim().length ||
          headTitle.getAttribute("data-limit"); // Use title text length by default, data-limit as an optional override
        textObject.Head = [
          {
            description: "Page Title",
            text: headTitle.textContent.trim(),
            limit: titleLimit,
          },
        ];
      }

      if (headDescription) {
        const descriptionText = headDescription.getAttribute("data-text");
        const descriptionLimit =
          headDescription.content.trim().length ||
          headDescription.getAttribute("data-limit"); // Use description text length by default, data-limit as an optional override
        textObject.Head.push({
          description: "Page Description",
          text: headDescription.content.trim(),
          limit: descriptionLimit,
        });
      }
    }

    console.log("textObject", textObject);

    return () => {
      // Cleanup function
    };
  }, []);

  const handleSectionsList = (list) => {
    setSectionsToggle(list);
    toggleSectionsRef.current = list;
  };

  const applySectionsSettings = (savedStyles) => {
    // console.log('applySectionsSettings', savedStyles, currentResolutionMode, selectedVersion)
    if (!savedStyles) return;
    // savedStyles[selectedVersion] =
    //   savedStyles[selectedVersion] || {};

    // savedStyles[selectedVersion][currentResolutionMode] =
    //   savedStyles[selectedVersion][currentResolutionMode] || {};

    const sectionsSettingsObj = savedStyles["saveGlobalSections"];
    console.log(
      "applySectionsSettings sectionsSettingsObj",
      sectionsSettingsObj,
    );

    if (sectionsSettingsObj) {
      const restoredSections = sectionsSettingsObj.global;
      // handleSectionsList(restoredSections);

      restoredSections.forEach((section, index) => {
        //* const sectionElement = innerPage.querySelector(
        //*   `[data-section-toggle="${section.name}"]`,
        //* );
        const sectionElement =
          innerPage.getElementById(section.id) ||
          innerPage.querySelector(`[data-section-toggle="${section.name}"]`);
        if (!section.order) {
          section.order = index + 1;
        }
        if (!section.id) {
          section.id = sectionElement?.id || section.name;
        }
        console.log("applySectionsSettings", sectionElement);

        if (sectionElement) {
          // console.log(
          //   "sectionElement",
          //   sectionElement,
          //   section.visible ? "block" : "none",
          // );
          console.log("applySectionsSettings", sectionElement);

          // sectionElement.style.display = section.visible ? 'block' : 'none';
          if (!section.visible) {
            sectionElement.classList.add("hidden");
          }
          // console.log("sectionElement: ", sectionElement);
          // sectionElement.style.setProperty("order", section.order);

          if (
            sectionElement.hasAttribute("data-switch-sections") &&
            section.activeVariant
          ) {
            const activeId = section.activeVariant;
            const sectionId = sectionElement.getAttribute(
              "data-switch-sections",
            );
            setTimeout(() => {
              const switchSectionsSwiper =
                iframeElement?.contentWindow?.switchSectionsSwiper;
              if (switchSectionsSwiper) {
                let currentSwiper = null;
                if (switchSectionsSwiper.length > 1)
                  currentSwiper = switchSectionsSwiper.find(
                    (swiper) =>
                      swiper.el
                        .closest("[data-switch-sections]")
                        .getAttribute("data-switch-sections") === sectionId,
                  );
                else {
                  currentSwiper = switchSectionsSwiper;
                }

                if (currentSwiper) {
                  const indexSlideTo = currentSwiper.slides.findIndex(
                    (slide) => slide.children[0].id === activeId,
                  );
                  currentSwiper.slideToLoop(indexSlideTo, 0, false);
                }
              }
            }, 0);
          }
        }
        if (section.duplicateId) {
          if (
            !restoredSections.some(
              (sectionInList) => section.duplicateId === sectionInList.id,
            )
          ) {
            const parentSection = restoredSections.find(
              (sectionInList) => section.duplicateId === sectionInList.name,
            );
            section.duplicateId = parentSection.id;
          }
          insertDuplicatedSection(section);
        }
      });
      handleSectionsList(restoredSections);
      setTimeout(() => {
        iframeElement.contentWindow?.aos?.refreshHard();
      }, 50);
    } else {
      const sectionsToToggle = Array.from(
        innerPage.querySelectorAll("[data-section-toggle]"),
      ).map((section, index) => {
        const sectionName = section.getAttribute("data-section-toggle");
        const sectionId = section.id;
        const isVisible = section.style.display !== "none";
        // section.style.setProperty("order", index + 1);
        return {
          id: sectionId,
          name: sectionName,
          visible: isVisible,
          order: index + 1,
        };
      });
      handleSectionsList(sectionsToToggle);
    }
  };
  const applySectionsOrders = () => {
    // console.log("toggleSectionsRef.current: ", toggleSectionsRef.current);
    toggleSectionsRef.current?.forEach((section, index) => {
      //* const sectionElement = innerPage.querySelector(
      //*   `[data-section-toggle="${section.name}"]`,
      //* );
      const sectionElement = innerPage.getElementById(section.id);
      // console.log("toggleSectionsRef.current sectionElement: ", sectionElement);

      if (sectionElement) {
        sectionElement.style.setProperty("order", section.order);
      }
    });
  };
  const handleToggleSections = (visible, sectionId) => {
    //* const sectionIndex = sectionsToToggle.findIndex(
    //*   (section) => section.name === sectionName,
    //* );
    const sectionIndex = sectionsToToggle.findIndex(
      (section) => section.id === sectionId,
    );
    if (sectionIndex !== -1) {
      const updatedSections = [...sectionsToToggle];
      updatedSections[sectionIndex].visible = visible;
      // console.log('handleTogglEsections',innerPage)
      //* const section = innerPage.querySelector(
      //*   `[data-section-toggle="${sectionName}"]`,
      //* );
      const section = innerPage.getElementById(sectionId);
      if (section) {
        if (updatedSections[sectionIndex].visible) {
          section.classList.remove("hidden");
          setTimeout(() => {
            iframeElement.contentWindow?.aos?.refreshHard();
          }, 50);
        } else {
          section.classList.add("hidden");
          setTimeout(() => {
            iframeElement.contentWindow?.aos?.refreshHard();
          }, 50);
        }
      }

      // Save the updated section visibility state to localStorage
      //* localStorage.setItem(
      //*   "sectionVisibilityState",
      //*   JSON.stringify(updatedSections),
      //* );
      console.log("updatedSections", updatedSections);
      handleSectionsList(updatedSections);
      return updatedSections;
    }
  };

  const handleToggleRemovedItems = (element, elementId) => {
    let newList = [];
    if (removedElements.includes(elementId)) {
      const prevRemovedElements = [...removedElements];
      newList = prevRemovedElements.filter((id) => id !== elementId);
      element.classList.remove("hidden");
    } else {
      const prevRemovedElements = [...removedElements];
      newList = [...prevRemovedElements, elementId];
      element.classList.add("hidden");
    }
    setRemovedElements(newList);

    const savedStyles = getGlobalDesignObject() || {};

    savedStyles["removedItems"] = savedStyles["removedItems"] || {};

    savedStyles["removedItems"].global = newList;
    handleUpdateGlobalDesignObject(savedStyles, handleToggleRemovedItems); // clear
    saveDesignObject();
  };
  const applyRemovedItems = (savedStyles) => {
    // var pageIdentifier = getPageIdentifier();
    const removedItemsObject = savedStyles["removedItems"] || {};

    if (removedItemsObject.global) {
      setRemovedElements(removedItemsObject.global);
      removedItemsObject.global.forEach((item) => {
        //* const element = innerPage.querySelector(`[data-remove="${item}"]`);
        const element = innerPage.getElementById(item);
        element?.classList.add("hidden");
      });
    } else {
      setRemovedElements([]);
    }
  };

  const addRemoveItemsSectionsIcons = () => {
    const elements = innerPage.querySelectorAll("[data-section-toggle]");

    ////console.log("elements", elements);

    elements.forEach((el) => {
      // Append the pencil icon to each selected element
      if (el.querySelector(".pencil-icon")) return;
      const icon = document.createElement("div");
      icon.className = "pencil-icon";
      icon.style.setProperty("top", "50%");
      icon.style.setProperty("transform", "translateY(-50%)");
      icon.innerHTML =
        "<button type='button' class='edit-add-action-btn' style='white-space: nowrap; background-color: #1E90FF !important; color: #fff !important'>Choose Section</button>";

      if (el.hasAttribute("data-edit-section-parent")) {
        el.closest("section").appendChild(icon);
      } else {
        el.appendChild(icon);
      }

      icon.onclick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenedSidebar("removeElements");

        const section = toggleSectionsRef.current.find(
          (sect) => sect.id === el.id,
        );
        // const element = innerPage.querySelector(
        //   `[data-section="${section.name}"]`,
        // );
        if (el.hasAttribute("data-switch-sections")) {
          const targetSection = el.querySelector(".swiper-slide-active")
            .children[0];
          setRemoveItemsSelectedSection({ ...section, element: targetSection });
        } else {
          setRemoveItemsSelectedSection({ ...section, element: el });
        }
      };
    });
  };

  //! Publish functions
  async function handleSavePageToPlatform(profile) {
    setHtmlContentLoading(true);

    if (profile.type === "landing") {
      const html = await getPageHTMLContent(profile.credentials);
      if (html) {
        const { 470: mobile, 1000: tablet, 1920: desktop } = html;
        const versions = { mobile, tablet, desktop };
        const params = {};

        Object.keys(versions).forEach((screenVersionHtml) => {
          const root = parse(versions[screenVersionHtml]);
          const header = root.querySelector("header.header--active")
          const body = root.querySelector("main");
          const switchSections = body.querySelectorAll(
            "[data-switch-sections]",
          );
          if (switchSections.length) {
            switchSections.forEach((sect) => {
              const activeSection = sect.querySelector(
                ".swiper-slide-active > [id]",
              );
              sect.replaceWith(activeSection);
            });
          }
          const footer = root.querySelector("footer");
          const dynamicStyles = root.querySelector("#dynamic-styles");

          const htmlParts = {
            header: header?.outerHTML || "",
            page: root.outerHTML,
            footer: footer.outerHTML,
            dynamicStyles: dynamicStyles.outerHTML,
          };

          params[screenVersionHtml] = htmlParts;
        });

        await fetchSaveHTMLContent(
          params,
          {
            login: profile.credentials.login,
            password: profile.credentials.password,
          },
          profile.credentials.endpoint,
        );
      }
      await fetchSaveGlobalColors();
    }

    setHtmlContentLoading(false);
  }
  async function getPageHTMLContent(data) {
    try {
      const res = await axios.get(
        `https://brainteaser.codersuccess.com/api/popup/convert?email=${data.email}&page=${data.page}&version=${data.version}`,
      );

      return res?.data || null;

      // const iframe = document.createElement("iframe");
      // iframe.src = `/?page=${data.page}&email=${data.email}&version=${data.version}`;
      // iframe.style.display = "none";
      // iframe.style.cssText =
      //   "position: absolute; top: 0; left: 0; width: 1px; height: 1px;";
      // document.body.appendChild(iframe);

      // const pageRequest = await fetch(
      //   `/?page=${data.page}&email=${data.email}&version=${data.version}`,
      // );
      // const page = pageRequest.text();

      // await syncTimeout(5000);
      // console.log("handleSavePageToPlatform iframe: ", iframe);
      // const html = await handleIframePage();
      // iframe.remove();

      // return html;

      // async function handleIframePage() {
      //   const innerIframePage =
      //     iframe.contentDocument.querySelector("#viewport-preview");

      //   if (innerIframePage) {
      //     const htmlContent =
      //       innerIframePage.contentDocument.documentElement.outerHTML;
      //     return { 470: htmlContent, 1100: htmlContent, 1920: htmlContent };
      //   } else {
      //     await syncTimeout(1000);
      //     return await handleIframePage();
      //   }
      // }
    } catch (error) {
      console.log("getPageHTMLContent error: ", error);
    }
  }
  async function fetchSaveHTMLContent(params, credentials, host) {
    const res = await fetchWPRequest(
      "post",
      "/editor/save-homepage-html",
      params,
      credentials,
      host,
    );
    await fetchRequiredFiles();
  }
  async function fetchRequiredFiles() {
    try {
      const headerCssFile = await getFile("/styles/header.css");
      const homepageCssFile = await getFile("/styles/style.css");
      const footerCssFile = await getFile("/styles/footer.css");
      const cssRTLFile = await getFile("/styles/style_rtl.css");
      const cssGeneralFile = await getFile("/styles/general.css");
      const jsFile = await getFile("/js/app.js");

      const formData = new FormData();
      formData.append("header-css", headerCssFile);
      formData.append("homepage-css", homepageCssFile);
      formData.append("footer-css", footerCssFile);
      formData.append("css_rtl", cssRTLFile);
      formData.append("css_general", cssGeneralFile);
      formData.append("js", jsFile);

      await axios.post(`${wpHost}/editor/save-required-files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Username": "gridnev",
          "X-Password": "C*ADw^Geq@zaV2ovla",
        },
      });
    } catch (error) {
      console.log("fetchRequiredFiles error: ", error);
    }
  }
  async function fetchSaveGlobalColors() {
    const stylesObject = getGlobalDesignObject();
    console.log("fetchSaveGlobalColors_stylesObject", stylesObject);
    const globalStyles = stylesObject ? stylesObject["globalStylesObj"] : null;
    if (globalStyles) {
      // const linkGlobalStyles = globalStyles.global?.find(
      //   (style) => style.title === "links",
      // )?.styles[".link-1"];
      // const linkGlobalHoverStyles = globalStyles.global?.find(
      //   (style) => style.title === "links",
      // )?.styles[".link-1:hover"];
      const siteMainColor =
        globalStyles.global
          ?.find((style) => style.title === "main colors")
          ?.styles[":root"]?.find((item) => item.rule === "--main-color")
          ?.value || null;
      const textGlobalStyles = globalStyles.global?.find(
        (style) => style.title === "text content",
      );

      let colorsObject = {
        "theme-color": colorToHex(siteMainColor),
        "link-color": colorToHex(siteMainColor),
        "link-hover-color": getHoverColor(colorToHex(siteMainColor)),
        "text-color": colorToHex(
          textGlobalStyles?.styles[".text-1"]?.find(
            (item) => item.rule === "color",
          )?.value || null,
        ),
        "heading-color": colorToHex(
          textGlobalStyles?.styles[".title"]?.find(
            (item) => item.rule === "color",
          )?.value || null,
        ),
      };
      Object.keys(colorsObject).forEach((key) => {
        if (colorsObject[key] === null) delete colorsObject[key];
      });
      console.log("publish global colors: ", colorsObject);

      const res = await fetchWPRequest(
        "post",
        "/editor/update-colors",
        colorsObject,
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <MenuProvider>
          <div
            ref={appRef}
            className="App"
          >
            {console.log("ViewportResizer")}

            {isStaticPage ? (
              <>
                <button
                  onClick={handleToggleStaticPage}
                  style={{
                    position: "fixed",
                    top: "10px",
                    background: "lightblue",
                    padding: "5px 10px",
                    borderRadius: "10px",
                    right: "50px",
                    zIndex: "100",
                  }}
                >
                  Toggle Static Page
                </button>

                <StaticPage />
              </>
            ) : (
              <>
                <ViewportResizer
                  handleToggleStaticPage={handleToggleStaticPage}
                  handleResolutionMode={handleResolutionMode}
                />
                <PortalContainer>
                  <SidebarNav
                    setVersion={setVersion}
                    addImageIcons={addImageIcons}
                    fetchDesignObject={fetchDesignObject}
                    addElPositionIcons={addElPositionIcons}
                    addPositionIcons={addPositionIcons}
                    addStyleIcons={addStyleIcons}
                    openGlobalStyles={openGlobalStyles}
                    togglePencils={togglePencils}
                    openedSidebar={openedSidebar}
                    toggleVersionPopup={toggleVersionPopup}
                    removeAllIcons={removeAllIcons}
                    setOpenedSidebar={setOpenedSidebar}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    token={token}
                    handlePageChange={handlePageChange}
                    allPages={allPages}
                    versions={versions}
                    version={version}
                    handleChangeCurrentVersion={handleChangeCurrentVersion}
                    handleCreatePage={handleCreatePage}
                    isLoggedIn={isLoggedIn}
                    selectedPage={selectedPage}
                    handleSavePageToPlatform={handleSavePageToPlatform}
                    htmlContentLoading={htmlContentLoading}
                    addRemoveItemsSectionsIcons={addRemoveItemsSectionsIcons}
                  />

                  {isSidebarOpen && openedSidebar === "versionsTab" && (
                    <VersionsTab
                      onClose={closeNdSave}
                      handleChangeCurrentVersion={handleChangeCurrentVersion}
                      setOpenedSidebar={setOpenedSidebar}
                      currentVersion={version}
                      saveDesignObject={saveDesignObject}
                      saveNewVersion={saveNewVersion}
                    />
                  )}

                  {isSidebarOpen && openedSidebar === "pagesTab" && (
                    <PagesTab
                      pageCreated={pageCreated}
                      setPageCreated={setPageCreated}
                      handleCreatePage={handleCreatePage}
                      setOpenedSidebar={setOpenedSidebar}
                    />
                  )}
                  {console.log("openedSidebar", openedSidebar)}
                  {isSidebarOpen && openedSidebar === "sectionsTab" && (
                    <SectionsToggle
                      sectionsToToggle={sectionsToToggle}
                      toggleSectionsRef={toggleSectionsRef}
                      handleSectionsList={handleSectionsList}
                      handleToggleSections={handleToggleSections}
                      version={version}
                      saveDesignObject={saveDesignObject}
                    />
                  )}
                  {isSidebarOpen && openedSidebar === "removeElements" && (
                    <RemoveElements
                      sections={sectionsToToggle}
                      iframe={innerPage}
                      removedElements={removedElements}
                      handleToggleRemovedItems={handleToggleRemovedItems}
                      removeItemsSelectedSection={removeItemsSelectedSection}
                    />
                  )}
                  {isSidebarOpen && openedSidebar === "publish" && (
                    <Publish
                      handleSavePageToPlatform={handleSavePageToPlatform}
                      htmlContentLoading={htmlContentLoading}
                      userEmail={userEmail}
                      version={version}
                    />
                  )}

                  {isSidebarOpen && openedSidebar === "editText" && (
                    <EditTextTab
                      onSave={handleSaveFontSize}
                      onClose={closeNdSave}
                      initialFontSize={initialFontSize}
                      initialColor={initialColor}
                      initialText={initialText}
                      initialFontWeight={initialFontWeight}
                    />
                  )}

                  {isSidebarOpen && openedSidebar === "editMenu" && (
                    <EditMenuPopup
                      onClose={closeNdSave}
                      innerPage={innerPage}
                      version={version}
                      currentResolutionMode={currentResolutionMode}
                      saveDesignObject={saveDesignObject}
                    />
                  )}

                  {isSidebarOpen && openedSidebar === "imageUpload" && (
                    <ImageUploader handleSaveImage={handleSaveImage} />
                  )}

                  {isSidebarOpen && openedSidebar === "popupImgBckgrndPstn" && (
                    <PopupBackgroundImagePosition
                      handleSaveImgBckPstn={handleSaveImgBckPstn}
                    />
                  )}

                  {isSidebarOpen &&
                    openedSidebar === "popupElementBckgrndPstn" && (
                      <PopupElementPosition
                        currentElement={currentElement}
                        version={version}
                        onClose={closeNdSave}
                        saveDesignObject={saveDesignObject}
                      />
                    )}

                  {isSidebarOpen && openedSidebar === "stylePopup" && (
                    <StyleEditorPopup
                      onClose={closeNdSave}
                      groupsList={currentGlobalGroups}
                      version={version}
                      saveDesignObject={saveDesignObject}
                    />
                  )}

                  {isSidebarOpen && openedSidebar === "versionPopup" && (
                    <PopupVersionManager
                      onClose={closeNdSave}
                      resetVErsionStyles={resetVErsionStyles}
                      userEmail={userEmail}
                      handleUserEmail={handleUserEmail}
                      token={token}
                      saveDesignObjectState={saveDesignObjectState}
                      saveDesignObject={saveDesignObject}
                      //setVersions={setVersions}
                      versions={versions}
                      isTokenValid={isTokenValid}
                    />
                  )}
                </PortalContainer>
              </>
            )}
          </div>

          <HeaderMenu
            innerPage={innerPage}
            version={version}
            handleChangeCurrentVersion={handleChangeCurrentVersion}
            currentResolutionMode={currentResolutionMode}
            saveDesignObject={saveDesignObject}
          />
        </MenuProvider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

export default App;
