import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popper,
  InputAdornment,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { 
  getGlobalDesignObject, 
  updateDynamicStyles,
  handleUpdateGlobalDesignObject,
  innerPage
} from "../../Services";
import { MuiColorInput } from "mui-color-input";
import { ColorizeOutlined } from "@mui/icons-material";
import { ColorInput } from "../../api/CommonComponents";

const StyleEditorPopup = ({
  onClose,
  groupsList, 
  version,
  saveDesignObject,
}) => {
  const [styleGroups, setStyleGroups] = useState([]);
  const [currentColor, setCurrentColor] = useState("");
  const [currentEditing, setCurrentEditing] = useState(null); // { groupTitle, selector, ruleName }

  // console.log("styleGroups", styleGroups);

  useEffect(() => {
    const styles = innerPage.getElementById("dynamic-styles").innerHTML;
    // const cleanedStyles = styles.replace(/\n|\s{2,}/g, '');
    const hiddenRegex = /\/\*\s*hidden\s*\*\/[\s\S]*?\/\*\s*hidden\s*\*\//g;
    const cleanedStyles = styles.replace(hiddenRegex, "");
    const groups = cleanedStyles.split(/(?=\/\*[^]*?\*\/)/g).filter(Boolean);
    console.log("styleGroups", groups, cleanedStyles);

    const allGroups = groups.map((group) => {
      const titleMatch = group.match(/\/\*\s*(.*?)\s*\*\//);
      const title = titleMatch ? titleMatch[1].trim() : "General";
      console.log("title", title);

      const parsedCSS = parseCSS(group);
      const styles = parsedCSS.reduce((acc, curr) => {
        acc[curr.selector] = curr.properties;
        return acc;
      }, {});

      return { title, styles };
    });

    setStyleGroups(allGroups);
  }, [groupsList]);

  const handleColorChange = (groupTitle, selector, ruleName, color) => {
    // const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    // setCurrentColor(color);
    if (groupTitle && selector && ruleName) {
      handleInputChange(groupTitle, selector, ruleName, color);
    }
  };
  
  const saveGlobalStyles = async (stylesObject) => {
    console.log("saveGlobalStyles", stylesObject);
    let savedStyles = getGlobalDesignObject();
    // const savedStyles = getCurrentStylesObject(version, currentResolutionMode);
    console.log('savedStyles', savedStyles)

    savedStyles =
      savedStyles || {};
    savedStyles[
      "globalStylesObj"
    ] =
      savedStyles[
      "globalStylesObj"
      ] || {};

    savedStyles["globalStylesObj"]["global"] = stylesObject;
    //const jsonObject = JSON.stringify(savedStyles);
    // localStorage.setItem("elementStyles", jsonObject);
    handleUpdateGlobalDesignObject(savedStyles, 'saveGlobalStyles');
    saveDesignObject();
  };

  const handleInputChange = (groupTitle, selector, ruleName, newValue) => {
    // console.log("handleInputChange", groupTitle, selector, ruleName, newValue);
    setStyleGroups((prev) => {
      const newStyleGroups = prev.map((group, index) => {
        if (group.title === groupTitle) {
          const updatedRules = group.styles[selector].map((rule) => {
            if (rule.rule === ruleName) {
              return { ...rule, value: newValue };
            }
            return rule;
          });
          return {
            ...group,
            styles: { ...group.styles, [selector]: updatedRules },
          };
        }
        return group;
      });

      setTimeout(() => updateDynamicStyles(newStyleGroups), 0);
      saveGlobalStyles(newStyleGroups);
      return newStyleGroups;
    });
  };

  function parseCSS(styles) {
    const cleanedStyles = styles.replace(/\/\*[\s\S]*?\*\//g, "");
    const rules = cleanedStyles
      .split("}")
      .map((rule) => rule.trim())
      .filter((rule) => rule.length);
    const parsedRules = [];

    rules.forEach((rule) => {
      const parts = rule.split("{").map((part) => part.trim());
      if (parts.length < 2) return;

      const [selector, propertiesString] = parts;
      const properties = propertiesString
        .split(";")
        .map((prop) => prop.trim())
        .filter((prop) => prop.length)
        .map((prop) => {
          const propParts = prop.split(":").map((part) => part.trim());
          if (propParts.length < 2) return null;

          const [rule, value] = propParts;
          return { rule, value };
        })
        .filter((prop) => prop !== null);

      if (properties.length) {
        parsedRules.push({ selector, properties });
      }
    });

    return parsedRules;
  }

  function isColorValue(value) {
    const colorRegex =
      /^(#(?:[0-9a-fA-F]{3}){1,2})|rgba?\((\d{1,3},\s?\d{1,3},\s?\d{1,3}(,\s?(0|1|0?\.\d+))?)\)$/;
    return colorRegex.test(value);
  }

  return (
    <DialogContent
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      style={{ position: "static", maxWidth: "sm", width: "100%" }}
    >
      <DialogTitle>Style Editor</DialogTitle>
      <DialogContent style={{ overflow: "auto", maxHeight: "350px" }}>
        {styleGroups
          .filter((group) => groupsList.includes(group.title))
          .map((group, groupIndex) => {
            const hasStyles = Object.keys(group.styles).length > 0;
            console.log("hasStyles", hasStyles, group.title, group.styles);
            if (!hasStyles) {
              return (
                <div key={group.title}>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      textAlign: "center",
                      marginBottom: "2rem",
                    }}
                    variant="subtitle1"
                  >
                    {group.title}
                  </Typography>
                </div>
              );
            }

            return (
              <Accordion key={group.title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">{group.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(group.styles).map(([selector, rules]) => (
                    <div key={selector}>
                      <Typography variant="subtitle2">{selector}</Typography>

                      {rules.map((rule, index) => (
                        <div key={index}>
                          <Typography variant="body2">{rule.rule}:</Typography>
                          {isColorValue(rule.value) ? (
                            <div>
                              <TextField
                                type="text"
                                fullWidth
                                value={rule.value}
                                onChange={(e) =>
                                  handleColorChange(
                                    group.title,
                                    selector,
                                    rule.rule,
                                    e.target.value,
                                  )
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ColorInput
                                        type="color"
                                        value={rule.value}
                                        onChange={(e) =>
                                          handleColorChange(
                                            group.title,
                                            selector,
                                            rule.rule,
                                            e.target.value,
                                          )
                                        }
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {/* <MuiColorInput
                                format="hex8"
                                value={rule.value}
                                onChange={(color) =>
                                  handleColorChange(
                                    group.title,
                                    selector,
                                    rule.rule,
                                    color,
                                  )
                                }
                                fullWidth
                                className="custom-cursor"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        onClick={open}
                                      >
                                        <ColorizeOutlined />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              /> */}
                            </div>
                          ) : rule.rule.includes("font-family") ? (
                            <ToggleButtonGroup
                              color="primary"
                              value={rule.value}
                              exclusive
                              onChange={(e, newValue) => {
                                if (newValue !== null)
                                  handleInputChange(
                                    group.title,
                                    selector,
                                    rule.rule,
                                    newValue,
                                  )
                              }}
                              aria-label="text alignment"
                            >
                              <ToggleButton
                                value={`"Manrope"`}
                                aria-label="Manrope"
                              >
                                Manrope
                              </ToggleButton>
                              <ToggleButton
                                value={`"Montserrat"`}
                                aria-label="Montserrat"
                              >
                                Montserrat
                              </ToggleButton>
                              <ToggleButton
                                value={`"Inter"`}
                                aria-label="Inter"
                              >
                                Inter
                              </ToggleButton>
                            </ToggleButtonGroup>
                          ) : (
                            <>
                              <TextField
                                size="small"
                                value={rule.value}
                                onChange={(e) =>
                                  handleInputChange(
                                    group.title,
                                    selector,
                                    rule.rule,
                                    e.target.value,
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </DialogContent>
    </DialogContent>
  );
};

export default StyleEditorPopup;
